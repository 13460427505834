<template>
  <div style="margin-bottom:3rem;">
    <div class="onlyProduct_solid">
      <h1>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;十九匠尊享套·一客一装</h1>
      <ul class="onlyProduct">
        <li class="li" v-for="(item, index) in formList" :key="index">
          <div @click="goHref(item.sku_link)" class="solid"></div>
          <img :src="item.goods_image" class="product_image" />
          <div class="info">
            <h2>{{ item.goods_spec }}</h2>
            <span>原价：¥{{ item.goods_marketprice }}/盒</span>
            <div class="model">
              <b class="price">¥{{ item.goods_price }}/盒</b>

              <template>
                <svg-icon
                  v-if="item.num > 0"
                  iconClass="sku_subtract_act"
                  class="svg"
                  @click.stop="skuSubtract(index)"
                ></svg-icon>
                <svg-icon
                  v-else
                  iconClass="sku_subtract"
                  class="svg"
                  @click.stop="skuSubtract(index)"
                ></svg-icon>
              </template>

              <b class="sku_num">{{ item.num }}</b>
              <svg-icon
                iconClass="sku_add"
                class="svg"
                @click.stop="skuAdd(index)"
              ></svg-icon>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <ul
      class="onluProduct_tool "
      :class="is_bind == true && level != 0 ? 'onluProduct_tool_act' : ''"
    >
      <li
        class="tool t1"
        @click="is_bind == true && level != 0 ? showTipsDialog() : showDialog()"
      >
        <svg-icon
          iconClass="authentication_act"
          v-if="is_bind == true && level != 0"
        ></svg-icon>
        <svg-icon iconClass="authentication" v-else></svg-icon>

        <p>
          {{
            is_bind == true && level != 0 ? "尊享身份已认证" : "去认证尊享身份"
          }}
        </p>
      </li>
      <li class="solid"></li>
      <li class="tool t2" @click="kefuBoo = true">
        <svg-icon iconClass="customer"></svg-icon>

        <p>咨询客服</p>
      </li>
    </ul>

    <div class="onlyProduct_Fxied">
      <p v-if="is_bind == true && level != 0 && totalNum < 10">
        总数量不能低于10盒
      </p>
      <div class="info">
        <div class="left">
          <p>共{{ totalNum }}件</p>
          <div class="sum">
            <b>合计：</b>
            <span>¥{{ totalSum }}</span>
          </div>
        </div>
        <div class="btn" @click="submit">
          去结算
        </div>
      </div>
    </div>

    <van-overlay :show="boo" @click="boo = false">
      <div class="wrapper" @click.stop>
        <div class="rule">
          <div class="solid">
            <span class="title">请绑定手机号码</span>
            <div class="input_text">
              <van-field
                v-model="phone"
                placeholder="请输入手机号"
                clearable
                :maxlength="11"
              />
            </div>

            <ul class="btn">
              <li @click="boo = false">
                取消
              </li>
              <li
                @click="setPhpne"
                :style="{ opacity: phone.length == 11 ? '1' : '0.3' }"
              >
                确认
              </li>
            </ul>

            <svg-icon
              icon-class="dialog_error"
              class="tips_error"
              @click="boo = false"
            ></svg-icon>
          </div>

          <!-- <div class="ruled">
            <span class="r_title">认证尊享身份</span>
            
            <div class="btn">
              认证
            </div>
            <div class="tips">
              <p>不是，其他手机认证</p>
              <svg-icon class="svg" icon-class="textMoreRight"></svg-icon>
            </div>
          </div> -->
        </div>
      </div>
    </van-overlay>

    <van-overlay :show="tipsBoo" @click="tipsBoo = false">
      <div class="wrapper" @click.stop>
        <div class="rule">
          <div class="solid solid2 ">
            <span class="title ">您还没有一客一装尊享身份哦！</span>
            <p class="tips">
              建议您留意一客一装直播售卖活动，或者是联系客服咨询获取方法
            </p>

            <ul class="btn">
              <li @click="tipsBoo = false">
                确认
              </li>
            </ul>

            <svg-icon
              icon-class="dialog_error"
              class="tips_error"
              @click="tipsBoo = false"
            ></svg-icon>
          </div>

          <!-- <div class="ruled">
            <span class="r_title">认证尊享身份</span>
            
            <div class="btn">
              认证
            </div>
            <div class="tips">
              <p>不是，其他手机认证</p>
              <svg-icon class="svg" icon-class="textMoreRight"></svg-icon>
            </div>
          </div> -->
        </div>
      </div>
    </van-overlay>

    <oChat :boo.sync="kefuBoo"></oChat>
  </div>
</template>

<script>
import { Overlay, Field, Toast, Dialog } from "vant";
import { post, get } from "@get/http.js";
import oChat from "@/view/me/view/chat/popup.vue";
export default {
  components: {
    vanOverlay: Overlay,
    vanField: Field,
    oChat,
    vanDialog: Dialog,
  },
  data() {
    return {
      boo: false,
      tipsBoo: false,
      kefuBoo: false,
      phone: "",
      formList: [],
      level: 0,
      is_bind: false,
      totalSum: 0,
      totalNum: 0,
      phone: "",
    };
  },

  async created() {
    Toast.loading({
      message: "获取中",
      forbidClick: true,
      duration: 0,
    });
    let { list, level, is_bind } = await get(
      "/lw.CustomerIndependent/getList",
      {
        headers: {
          "X-DS-KEY": this.$store.state.user.token,
        },
      }
    );
    Toast.clear();
    for (let index = 0; index < list.length; index++) {
      list[index].num = 0;
    }
    this.is_bind = is_bind;
    if (is_bind == false) {
      this.boo = true;
    }
    this.level = level;
    this.formList = list;
  },

  methods: {
    goHref(href) {
      window.location.href = href;
    },
    showDialog() {
      if (this.is_bind == false) {
        this.boo = true;
      }
      if (this.is_bind == true && this.level == 0) {
        this.tipsBoo = true;
      }

      if (this.is_bind == true && this.level != 0) {
      }
    },
    async showTipsDialog() {
      if (this.level == 0) {
        this.tipsBoo = true;
      }
    },
    async setPhpne() {
      if (this.phone.length != 11) {
        Toast("请输入11位正确的手机号码");
        return false;
      }
      Toast.loading({
        message: "获取中",
        forbidClick: true,
        duration: 0,
      });
      let data = await post("/lw.CustomerIndependent/saveCommunityPhone", {
        data: {
          phone: this.phone,
        },
      });

      this.boo = false;
      let { level, is_bind } = await get("/lw.CustomerIndependent/getList", {
        headers: {
          "X-DS-KEY": this.$store.state.user.token,
        },
      });
      Toast(data.message);
      if (level != 0) {
        window.location.reload();
      } else {
        Toast.clear();
        this.tipsBoo = true;
        this.is_bind = is_bind;
      }

      // if(this.level == 0){

      // }
    },
    sumPrice() {
      let sum = 0;
      let num = 0;
      for (let index = 0; index < this.formList.length; index++) {
        sum +=
          parseFloat(this.formList[index].goods_price) *
          this.formList[index].num;
        num += this.formList[index].num;
      }
      this.totalSum = parseFloat(sum).toFixed(2);
      this.totalNum = num;
    },
    skuSubtract(index) {
      if (this.formList[index].num == 0) {
        Toast("不能再少了~");
        return false;
      } else {
        this.formList[index].num--;
        this.sumPrice();
      }
    },
    skuAdd(index) {
      this.formList[index].num++;
      this.sumPrice();
    },
    submit() {
      let f = this.formList,
        sum = 0;
      for (let index = 0; index < f.length; index++) {
        sum += f[index].num;
      }
      if (sum == 0) {
        Toast("你需要买点什么哦~");
        return false;
      }

      if (this.is_bind == false) {
        Dialog.confirm({
          title: "提示",
          message: "您需要绑定手机号码再能继续操作哦~",
        })
          .then(() => {
            // on confirm
            this.boo = true;
          })
          .catch(() => {
            // on cancel
          });
        return false;
      }
      if (sum < 10 && this.level != 0 && this.is_bind == true) {
        Toast("购买总数量不能低于10盒");
      } else {
        let cart_id = "";
        for (let index = 0; index < this.formList.length; index++) {
          if (this.formList[index].num != 0) {
            cart_id += `${this.formList[index].goods_id}|${
              this.formList[index].num
            }${index != this.formList.length - 1 ? "," : ""}`;
          }
        }
        let params = {
          buy_now: 1,
          cart_id,
        };

        this.$router.replace({ name: "Buy_Only", query: params });

        // Toast("购买成功");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.onlyProduct_solid {
  width: rem(690);
  background: #ffffff;
  border-radius: rem(16);
  margin: rem(32) auto;
  padding: rem(32) rem(24);
  box-sizing: border-box;

  > h1 {
    font-weight: bold;
    font-weight: bold;
    font-size: rem(28);
  }
}
.onlyProduct {
  .li {
    margin-top: rem(32);
    display: flex;
    box-sizing: border-box;
    position: relative;
    .solid {
      position: absolute;
      top: 0;
      left: 0;
      width: 75%;
      height: 100%;
    }
    .product_image {
      width: rem(184);
      height: rem(184);
      border-radius: rem(16);
      margin-right: rem(16);
    }
    .info {
      flex: 1;
      > h2 {
        font-size: rem(28);
        color: #222222;
        height: rem(92);
        @include ell;
        margin-top: rem(10);
      }
      > span {
        font-size: rem(22);
        color: #989898;
      }
      .model {
        display: flex;
        align-items: center;
        font-size: rem(30);
        .price {
          color: #ff4848;
          font-weight: bold;
          flex: 1;
        }
        .sku_num {
          width: rem(30);
          display: inline-block;
          text-align: center;
          color: #222222;
          padding: 0 rem(10) 0 rem(6);
        }
        .svg {
          width: rem(38);
          height: rem(38);
        }
      }
    }
  }
}

.onluProduct_tool {
  display: flex;
  width: rem(690);
  height: rem(98);
  background: #ffffff;
  border-radius: rem(16);
  margin: 0 auto;
  align-items: center;
  .tool {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666666;
    font-size: rem(28);
    svg {
      margin-right: rem(12);
    }
  }
  .t1 {
    svg {
      width: rem(32);
    }
  }
  .t2 {
    svg {
      width: rem(34);
    }
  }
  .solid {
    width: rem(2);
    height: rem(40);
    background: #c4c4c4;
  }
}

.onluProduct_tool_act {
  .t1 {
    color: #07c160;
  }
  svg {
    color: #07c160;
  }
}

.solid2 {
  .title {
    font-size: rem(30);
  }
  .tips {
    font-size: rem(28);
    box-sizing: border-box;
    padding: 0 rem(42);
    margin-top: rem(24);
  }
  .btn {
    border: rem(2) solid #ff4848;
    border-radius: rem(42);
    width: rem(380);
    height: rem(84);
    margin: rem(56) auto 0;

    li {
      color: #ff4848 !important;
      background: none !important;
      border: none !important;
      opacity: 1 !important;
      font-size: rem(30);
    }
  }
}

.onlyProduct_Fxied {
  position: fixed;
  left: 0px;
  width: 100%;
  bottom: 0px;

  box-sizing: border-box;
  > p {
    background: #fff6e7;
    color: #fb7e1c;
    text-align: center;
    width: 100%;
    font-size: rem(22);
    line-height: rem(50);
    height: rem(50);
  }
  .info {
    display: flex;
    padding: rem(30) rem(30);
    box-sizing: border-box;
    width: 100%;
    height: rem(100);
    align-items: center;
    .left {
      flex: 1;
      > p {
        color: #222222;
        font-size: rem(24);
      }
      .sum {
        b {
          color: #222222;
          font-weight: bold;
          font-size: rem(28);
        }
        span {
          color: #ff4848;
          font-weight: bold;
          font-size: rem(34);
        }
      }
    }
    .btn {
      background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
      border-radius: rem(40);
      width: rem(278);
      height: rem(80);
      font-weight: bold;
      font-size: rem(28);
      line-height: rem(80);
      text-align: center;

      color: #ffffff;
    }
    background: #ffffff;
  }
}

.tips_error {
  position: absolute;
  width: rem(63);
  height: rem(63);
  bottom: rem(-120);
  left: 0;
  right: 0;
  margin: 0 auto;
}
.ruled {
  padding: rem(56) 0;
  .tips {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    p {
      font-size: rem(26);
      color: #666666;
    }
    svg {
      width: rem(11);
      height: rem(22);
    }
  }

  .r_title {
    i {
      color: #3996ec;
    }
    color: #222222;
    font-weight: bold;
    font-size: rem(34);
    text-align: center;
    display: block;
    // margin-top: rem(40);
    margin-bottom: rem(56);
  }
  .btn {
    width: rem(480);
    height: rem(84);
    line-height: rem(84);
    text-align: center;
    color: #ffffff;
    font-size: rem(30);
    margin: 0 auto rem(30);
    /* 渐变色 */

    background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
    border-radius: rem(42);
  }
}
.rule {
  width: rem(558);
  height: rem(385);
  background: #fff;
  border-radius: rem(16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  > .solid {
    .title {
      color: #222222;
      font-weight: bold;
      font-size: rem(34);
      text-align: center;
      display: block;
      margin-top: rem(40);
    }
    .input_text {
      margin: rem(20) rem(48) rem(56);
      border-bottom: 1px solid #c4c4c4;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      .act {
        li {
          &:last-of-type {
            opacity: 1;
          }
        }
      }
      li {
        width: rem(216);
        height: rem(84);
        box-sizing: border-box;
        border-radius: rem(42);
        box-sizing: border-box;
        text-align: center;
        line-height: rem(84);
        &:first-of-type {
          color: #666666;
          border: rem(2) solid #989898;
          margin-right: rem(30);
        }
        &:last-of-type {
          background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
          color: #ffffff;
          opacity: 0.3;
        }
      }
      .l_act {
        opacity: 1;
      }
    }
  }
}
</style>
